import React, { createContext, useContext, useState, useEffect, useRef, ReactNode } from 'react';

interface SymbolsContextType {
  stockData: Record<string, any>;
  isWebsocketConnected: boolean;
}

interface SymbolsProviderProps {
  children: ReactNode;
}

const SymbolsContext = createContext<SymbolsContextType | undefined>(undefined);

const WEBSOCKET_URL = process.env.NEXT_PUBLIC_WEBSOCKET_URL;
const CACHE_KEY = 'stockDataCache'; // Key for stockData localStorage cache

const INITIAL_RECONNECT_INTERVAL = 10000; // Start with 10 seconds
const MAX_RECONNECT_INTERVAL = 60000; // Cap at 1 minute

export const SymbolsProvider: React.FC<SymbolsProviderProps> = ({ children }) => {
  const [stockData, setStockData] = useState<Record<string, any>>({});
  const [isWebsocketConnected, setIsWebsocketConnected] = useState<boolean>(false);
  const ws = useRef<WebSocket | null>(null);
  const reconnectTimeout = useRef<NodeJS.Timeout | null>(null);
  const reconnectInterval = useRef<number>(INITIAL_RECONNECT_INTERVAL);

  // Load cached stock data from localStorage
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const cachedData = localStorage.getItem(CACHE_KEY);
      if (cachedData) {
        console.log("Loaded cached stock data from localStorage:", cachedData);
        setStockData(JSON.parse(cachedData));
      } else {
        console.log("No cached stock data found in localStorage.");
      }
    }
  }, []);

  const handleWebSocketMessage = (event: MessageEvent) => {
    console.log("WebSocket message event triggered.");
    try {
      const newPriceData = JSON.parse(event.data);
      console.log("Parsed WebSocket message data:", newPriceData);

      if (newPriceData.message === 'Forbidden') {
        console.error("WebSocket message indicates 'Forbidden' access. Aborting update.");
        return;
      }

      setStockData((prevData) => {
        const updatedData = { ...prevData, ...newPriceData };
        console.log("Updating stock data with new data:", updatedData);
        localStorage.setItem(CACHE_KEY, JSON.stringify(updatedData));
        return updatedData;
      });
    } catch (error) {
      console.error("Error parsing WebSocket message:", error, "Message content:", event.data);
    }
  };

  const initializeWebSocket = () => {
    if (!ws.current) {
      console.log("Initializing new WebSocket connection to:", WEBSOCKET_URL);
      ws.current = new WebSocket(WEBSOCKET_URL);

      ws.current.onopen = () => {
        console.log("WebSocket connection successfully established. Connection ID:", ws.current?.url);
        setIsWebsocketConnected(true);
        
        // Reset the reconnect interval on successful connection
        reconnectInterval.current = INITIAL_RECONNECT_INTERVAL;
        console.log("Reconnect interval reset to:", reconnectInterval.current);
      };

      ws.current.onmessage = handleWebSocketMessage;

      ws.current.onclose = (event) => {
        console.warn("WebSocket connection closed. Connection ID:", ws.current?.url, "Code:", event.code, "Reason:", event.reason);
        setIsWebsocketConnected(false);
        ws.current = null;

        // Schedule reconnection with exponential backoff
        if (reconnectTimeout.current) clearTimeout(reconnectTimeout.current);
        reconnectTimeout.current = setTimeout(() => {
          console.log(`Attempting to reconnect in ${reconnectInterval.current / 1000} seconds...`);
          initializeWebSocket();

          // Increase the reconnect interval for exponential backoff, up to the maximum
          reconnectInterval.current = Math.min(reconnectInterval.current * 2, MAX_RECONNECT_INTERVAL);
          console.log(`Next reconnection interval set to ${reconnectInterval.current / 1000} seconds.`);
        }, reconnectInterval.current);
      };

      ws.current.onerror = (error) => {
        console.error("WebSocket encountered an error on Connection ID:", ws.current?.url, "Error:", error);
        setIsWebsocketConnected(false);
      };
    } else {
      console.log("WebSocket is already initialized, skipping re-initialization.");
    }
  };

  useEffect(() => {
    console.log("Starting WebSocket connection process...");
    initializeWebSocket();

    return () => {
      console.log("Cleaning up WebSocket connection...");
      if (ws.current) {
        console.log("Closing existing WebSocket connection. Connection ID:", ws.current?.url);
        ws.current.close();
        ws.current = null;
      }
      if (reconnectTimeout.current) {
        console.log("Clearing reconnection timeout.");
        clearTimeout(reconnectTimeout.current);
      }
      console.log("WebSocket cleanup complete.");
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SymbolsContext.Provider value={{ stockData, isWebsocketConnected }}>
      {children}
    </SymbolsContext.Provider>
  );
};

export default SymbolsContext;
